import * as React from "react"
import SCButtonGhost from "../buttons/SCGhostButton";
import { Modal, Input, Checkbox, FormControlLabel } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import SCButton from '../buttons/SCButton'
import VpnKeyIcon from '@material-ui/icons/VpnKey';


const PasswordResetConfirm = ({ onSignInRequest }) => {


    return (
        <main className="bg-white py-6 px-4 rounded-xl">
            <div className="flex items-center h-12 mb-8">
                <div className="flex justify-center items-center w-12 h-12 mr-2 rounded-full shadow border text-sc-primary">
                    <VpnKeyIcon></VpnKeyIcon>
                </div>
                <h3 className="text-sc-black mb-0">¡Bienvenido(a) de vuelta a Shift!</h3>
            </div>
            <hr className="h-1"></hr>
            <div className="w-full mb-8">
                <p>Te hemos enviado un correo electrónico con las instrucciones para restablecer tu contraseña </p>
            </div>
            <div className="mb-6">
                <SCButton onClick={onSignInRequest}>Aceptar</SCButton>
            </div>
            <div>
            </div>

        </main>)
}

export default PasswordResetConfirm;
