import * as React from "react"


const SCButtonGhost = ({children, onClick}) => {
    return(<div className="border border-sc-primary rounded-full p-2 text-sc-titles cursor-pointer" onClick={onClick}>
        <div className="text-center">
        {children}
        </div>
        </div>)
}

export default SCButtonGhost; 