import * as React from "react"
import PasswordResetConfirm from "../../../components/forms/PasswordResetConfirm"
import PasswordResetForm from "../../../components/forms/passwordResetRequestForm"
import { navigate } from "gatsby-link"

const ResetPasswordPage = () => {

    const [form, setForm ] = React.useState("reset")
    const backToLogin = () => {
        navigate("/pwa/account/login")
    }
    return(<div className="w-full h-screen flex justify-center items-center" style={{backgroundColor: '#C4C4C4'}}>

        <div style={{width: '90%'}}>
            
            {form === "reset" ? 

                    <PasswordResetForm onRequestSuccess={()=>{setForm("")}} onSignInRequqest={backToLogin}></PasswordResetForm>  :
                    <PasswordResetConfirm onSignInRequest={backToLogin}></PasswordResetConfirm>
            }                        
        </div>

    </div>)
}

export default ResetPasswordPage