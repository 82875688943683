import * as React from "react"
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import Error from '@material-ui/icons/Error'
import SCButton from '../buttons/SCButton';
import firebase from "firebase";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InputTexfieldRegex from "./InputTexfieldRegex";
import { validateEmail } from "./helper/validationsRegexFuncs.js"


const ResetPasswordRequestForm = ({ onSignInRequqest, onResetRequest, onRequestSuccess }) => {

    const [userEmail, setUserEmail] = React.useState({
        email: ""
    });
    const [error, setError] = React.useState();
    const [iconStatus, setIconStatus] = React.useState(undefined);

    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;



    const reset = () => {

        if (validate()) {
            firebase.auth().sendPasswordResetEmail(userEmail.email).then(() => {
                if (onRequestSuccess)
                    onRequestSuccess()
            }).catch(error => {
                // authMessageError(error.code)
                console.log(error.code)
                onRequestSuccess()
            })

        } else {
            console.log(authMessageError('Usuario no encontrado'))
        }
    }

    const authMessageError = (code) => {
        console.log(code)
        let message = "";
        switch (code) {
            case "auth/user-not-found":
                message = "Usuario no encontrado";
                break;
            case "auth/popup-closed-by-user":
                message = "El usuario canceló el inicio de sesión"
                break;
            case "auth/invalid-email":
                message = "El correo electrónico no tiene un formato correcto";
                break;
            default:
                message = code;
        }
        return message;
    }

    const updateProperty = (property, value) => {
        setUserEmail({...userEmail, [property]: value })
    }

    const validate = () => {
        if (!userEmail.email) {
            setError("Ingresa tu correo electrónico")
            setIconStatus(false)
            return false;
        } else if (!emailRegex.test(userEmail.email)) {
            setError("Correo Electronico inválido")
            setIconStatus(false)
            return false;
        }
        setIconStatus(true)
        return true;
    }

    return (
        <main className="bg-white p-3 rounded-xl">
            <div className="flex items-center justify-center h-12 mb-2">
                <div className="flex justify-center items-center w-12 h-12 mr-2 rounded-full shadow border text-sc-primary">
                    <VpnKeyIcon></VpnKeyIcon>
                </div>
                <h3 className="text-sc-black mb-0" style={{ fontSize: 20 }}>Recuperar contraseña</h3>
            </div>

            <hr className="h-1"></hr>

            <div className="w-full mb-8">
                <p>Enviaremos un link a tu correo electrónico con instrucciones para que puedas recuperar tu contraseña</p>
                <InputTexfieldRegex 
                    onChange={userEmail.email}    
                    setOnChange={updateProperty}
                    property={'email'} 
                    placeHolder={"Email"} 
                    validateFunc={validateEmail}
                    type='other'
                    >
                    <MailIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
            </div>


            {error ? <div className="text-red-600 text-center mb-4">{error}</div> : null}
            <div className="mb-4 flex justify-center">
                <div className="shadow-md rounded-full" style={{ width: 250 }}>
                    <SCButton onClick={reset}><b>Enviar</b></SCButton>
                </div>
            </div>

            <div>
                <div className="mb-4">
                    <div style={{ cursor: "pointer" }}><p className="text-center" onClick={onSignInRequqest}>Cancelar</p></div>
                </div>
            </div>

        </main>)
}

export default ResetPasswordRequestForm;
